
// Need to come up with a better way to do router definitions
import Routes from '@/routes'
import Link from 'next/link'

const toQueryParams = string => {
  const params = new URLSearchParams(string)
  const entries = params.entries()
  let result = {}
  for(let entry of entries) { // each 'entry' is a [key, value] tuple
    const [key, value] = entry;
    result[key] = value;
  }
  return result
}

const resolve = url => {
  // Link to something else, http://, https://, //
  if ('string' !== typeof url || url.match(/^(https?:)?\/\//)) {
    return false
  }
  const [pathname, queryString] = url.split('?')
  const queryParams = queryString ? toQueryParams(queryString) : {}
  for (let index = 0; index < Routes.length; index++) {
    let { route, to, params } = Routes[index]
    if ('string' === typeof route && pathname === to) {
      return { pathname: to, params: queryParams }
    }
    if (route.constructor === RegExp && route.test(pathname)) {
      const [ _, ...groups ] = pathname.match(route)
      return {
        pathname: to,
        query: {
          ...params.reduce((h, k, m) => ({ ...h, [k]: groups[m] }), {}),
          ...queryParams
        }
      }
    }
  }
  return false
}

const regular = ({ href, children, rest }) => (
  <a href={href} {...rest}>
    {children}
  </a>
)

const universal = ({ route: { pathname, query }, children, href, rest }) => (
  <Link as={href} href={{ pathname, query }} prefetch={false}>
    <a {...rest}>
      {children}
    </a>
  </Link>
)

const UniversalLink = ({ href, children, ...rest }) => {
  const route = resolve(href)
  return route
    ? universal({ route, children, href, rest })
    : regular({ href, children, rest })
}

export default UniversalLink
