const { loadExternalRoutes } = require('./ServerSide/loadExternalRoutes')
const proxyGuides = require('./ServerSide/proxyGuides')

const ssrApis = [
  {
    route: '/ssr-api/clp',
    to: '/api/category',
  },
  {
    route: '/ssr-api/category',
    to: '/api/category',
  },
  {
    route: '/ssr-api/search',
    to: '/api/search',
  },
  {
    route: '/ssr-api/get-plp-filters',
    to: '/api/getPLPFiltersFromDMP'
  },
  {
    route: '/ssr-api/get-meganav',
    to: '/api/getMegaNav'    
  },
  {
    route: '/ssr-api/get-product',
    to: '/api/getFromDMPBySKU',
  },
  {
    route: '/ssr-api/suggest',
    to: '/api/suggest',
  },
  {
    route: '/ssr-api/get-search-redirects',
    to: '/api/getSearchRedirectsFromDMP',
  },
  {
    route: '/ssr-api/get-dmp-record',
    to: '/api/getDMPRecord',
  }
]

const getRoutes = async () => [
  {
    route: '/',
    to: '/external-page',
  },
  ...await loadExternalRoutes(),
  ...ssrApis,
  {
    route: '/search.php',
    to: '/search',
  },
  {
    route: /^\/c-(\d+)-([a-z0-9-]+)\/?$/,
    to: '/category',
    params: ['legacyId', 'slug'],
  },
  {
    route:  /^\/new-category-(\d+)-([a-z0-9-]+)\/?$/,
    to: '/new-category',
    params: ['legacyId', 'slug'],
  },
  {
    route: '/cart.php',
    to: '/cart',
    params: [],
  },
  {
    route: '/cart',
    to: '/cart',
    params: [],
  },
  {
    route: /^\/checkout\/order-confirmation\/(\d+)\/?$/,
    to: '/order-confirmation',
    params: ['orderId'],
  },
  {
    route: /^\/(my-account|account)\/?$/,
    to: '/account',
  },
  {
    route: /^\/t-store-locations\/?$/,
    to: '/store-locations',
  },
  {
    route: /^\/store-locations\/?$/,
    to: '/store-locations',
  },
  {
    route: /^\/checkout\/?(shipping|delivery|payment)?\/?$/,
    to: '/checkout',
    params: ['tabId'],
  },
  {
    route: /^\/p-([a-z0-9-]+)-(\d+)\/?$/,
    to: '/product',
    params: ['slug', 'primarySku'],
  },
  {
    route: /^\/reservation\/([A-Za-z0-9]+)\/?$/,
    to: '/reservation',
    params: ['reservationId'],
  },
  {
    route: /^\/inspiration\/([A-Za-z0-9\-]+)\/?$/,
    to: '/inspiration',
    params: ['inspid'],
  },
  {
    route: '/login',
    to: '/login',
    params: [],
  },
  {
    route: '/forgot-password',
    to: '/forgot-password',
    params: [],
  },
  {
    route: '/change-password',
    to: '/change-password',
    params: [],
  },
  {
    route:  /^\/customer-service\/([A-Za-z0-9-]+)\/?$/,
    to: '/customer-service',
    params: ['slug'],
  },
  {
    route: /^\/t-([A-Za-z0-9-]+)\/?$/,
    to: '/customer-service',
    params: ['slug'],
  },
  {
    route: '/email-preference',
    to: '/email-preference',
    params: [],
  },
  {
    route: '/new-search',
    to: '/new-search',
    params: [],
  },
  {
    route: /^\/guides\/?[A-Za-z0-9-\?=\/]{0,64}/,
    resolve: proxyGuides('https://guides.zgallerie.com/wordpress'),
  },
  {
    route: '*',
    to: '/external-page',
  }
]

module.exports = { getRoutes }
