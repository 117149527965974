const Axios = require('axios')

function proxyGuides(base) {
  return async function handleHttpRequest(req, res) {
    const url = `${base}${req?.path.split('guides')[1] ?? ''}`
    try {
      const { data } = await Axios({
        url,
        method: 'get',
        responseType: 'document',
        headers: { accept: 'text/html,application/xhtml+xml,application/xml;' },
      })
      res.writeHead(200, { 'Content-Type': 'text/html', 'Content-Length': data.length })
      res.end(data)
    } catch (e) {
      console.log(e)
      res.status(500).send(`Unable to proxy to ${url}`)
    }
  }
}

module.exports = proxyGuides
