const { READONLY_BC_TOKEN, SHOP_HASH, SHOP_ORIGIN } = require('../services/Configuration')
const Axios = require('axios')

const MAX_REDIRECT_PAGES = 20 // 20 pages * 250 routes / page = 5000 total routes with this

function isEntryValid(entry) {
  if (typeof entry === 'string') {
    return true
  }
  if (!entry || !entry.route) {
    return false
  }
  if (typeof entry.resolve === 'function') {
    return true
  }
  if (typeof entry.route === 'string') {
    return true
  }
  if (Object.prototype.toString.call(entry.route) === '[object RegExp]' && typeof entry.to === 'string') {
    return true
  }
  return false
}

async function fetchRedirectRoutes() {
  let routes = []
  for (let i = 1; i <= MAX_REDIRECT_PAGES; i++) {
    const response = await Axios.get(
      `https://api.bigcommerce.com/stores/${SHOP_HASH}/v3/storefront/redirects?limit=250&page=${i}`,
      { headers: { 'X-Auth-Token': READONLY_BC_TOKEN } },
    )
    const currentRoutes = response.data.data
    if (currentRoutes.length === 0) {
      break
    } else {
      routes = [...routes, ...currentRoutes]
    }
  }
  console.log(`Retrieved a total of ${routes.length} redirects`)
  return routes
}

async function redirectRoutes() {
  try {
    const unfilteredRoutes = await fetchRedirectRoutes()
    const routes = unfilteredRoutes
      .filter((route) => !!route.to.url && route.to.type === 'url')
      .map((route) => {
        try {
          const from = route?.from_path?.replace(/\+/g, '%20')
          const toPath = String(route?.to?.url)
          if(!toPath || !from) {
            return null
          }
          const to = `${toPath.startsWith('/') ? SHOP_ORIGIN: ''}${toPath}`
          const routeEntry = {
            route: from,
            resolve: (req, res) => {
              res.redirect(301, to)
            },
          }
          console.log(`Redirecting ${from} to ${to}`)
          return routeEntry
        } catch {
          return null
        }
      })
      .filter((entry) => !!entry)
    return routes
  } catch {
    return []
  }
}
const getPageByPageNumber = async (pageNumber) => {
  console.log('getPageByPageNumber #', pageNumber)
  return (await Axios.get(`${SHOP_ORIGIN}/stores/${SHOP_HASH}/v3/content/pages?limit=10&page=${pageNumber}`, { headers: { 'X-Auth-Token': READONLY_BC_TOKEN } })).data
}
const getListOfPages = async () => {
  console.time('getInitialPageData')
  const { data: initialList, meta } = await getPageByPageNumber(1)
  console.timeEnd('getInitialPageData')
  console.time('getRemainingPages')
  const listsFromRemainingPages = await Promise.all(Array.from({
    length: meta.pagination.total_pages - 1
  }, (_, i) => (
    getPageByPageNumber(i + 2)
  )))
  console.timeEnd('getRemainingPages')
  const lists = [initialList, ...listsFromRemainingPages.map(({ data }) => data)].flat()
  return lists
}

async function generateBCPageMap () {
  const lists = await getListOfPages()
  const pagesMap = lists.reduce((acc, pageListing) => ({
    ...acc,
    [pageListing?.is_homepage && pageListing?.id ? '/' : pageListing.url]: pageListing.id
  }), {})
  return pagesMap 
}

async function bcPageRoutes() {
  const map = await generateBCPageMap()
  return map
}

async function marketingRoutes({ destinationRoute }) {
  try {
    const listOfPages = await getListOfPages()
    const routes = listOfPages
      .filter((route) => !!route.url)
      .map((route) => ({
        route: route.url,
        to: destinationRoute,
      }))
    return routes
  } catch (err){
    console.error('marketingRoutes error', err)
    return []
  }
}

async function loadExternalRoutes() {
  try {
    let routes = await Promise.all([
      // marketingRoutes({ destinationRoute: '/external-page' }),
      redirectRoutes(),
    ])

    routes = routes.flat().filter((entry) => {
      if (isEntryValid(entry)) {
        return true
      }
      console.error('You have supplied invalid route:', entry)
      return false
    })
    console.log(`Loaded a total of ${routes.length} routes`)
    return routes
  } catch {
    return []
  }
}

module.exports = {
  loadExternalRoutes,
  bcPageRoutes
}
